import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { TooltipService } from '../tooltip/tooltip.service';
import { StoreService } from './store.service';
import { LoaderService } from './loader.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class InterceptorWrapperService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loggerService: LoggerService,
    private tooltipService: TooltipService,
    private storeService: StoreService,
    private loaderService: LoaderService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isApiUrl(request, environment.apiUrl)) {
      request = this.preConfigure(request);
      this.loaderService.setLoading(true);
    }

    return next.handle(request).pipe(
      map(event => {
        this.loggerService.logMessage('Executing http request: ' + request.url);
        return event;
      }),
      catchError(err => {
        this.processError(err);
        return throwError(err);
      }),
      finalize(() => {
        if (this.isApiUrl(request, environment.apiUrl)) {
          this.loaderService.setLoading(false);
        }
      })
    );
  }

  private preConfigure(request: HttpRequest<any>) {
    if (this.authService.hasValidIdToken() && request.url.includes(environment.apiUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getIdToken()}`
        }
      });
    }

    return request;
  }

  private processError(err: any) {
    let errorMessage: string;

    if (err.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.loggerService.logException(err, errorMessage);
    // this.tooltipService.showError(err, '');
  }

  private isApiUrl(request: HttpRequest<any>, apiUrl: string) {
    return request.url.indexOf(apiUrl) !== -1;
  }
}
