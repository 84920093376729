<div class="a-header d-flex h-auto align-items-center a-px-20 a-pb-10 flex-wrap">
  <div class="a-header-logo d-flex height-50 align-items-center order-1 mr-2 mr-lg-0">
    <div class="a-pwc-logo a-md" routerLink="/"></div>
    <span class="divider a-mx-20 d-none d-sm-inline-block"></span>
    <div class="logo font-weight-medium a-nowrap">{{ title }}</div>
    <span class="divider a-mx-20 d-none d-sm-inline-block"></span>
  </div>
  <div class="a-header-menu d-flex height-50 align-items-center w-auto order-2">
    <div class="h-menu">
      <ng-container *ngIf="appUser.isAuthenticated">
        <pwc-app-menu></pwc-app-menu>
      </ng-container>
    </div>
  </div>
  <div class="a-header-profile d-flex height-50 align-items-center ml-auto order-3">
    <span class="divider a-mx-10"></span>
    <div class="d-flex align-items-center a-pl-10">
      <ng-container *ngIf="appUser.isAuthenticated">
        <div class="a-logout">
          <button aria-expanded="false" aria-haspopup="true" class="user-name a-text-white a-bg-primary"
            data-toggle="dropdown">
            {{ appUser.initials }}
          </button>
          <div class="dropdown-menu dropdown-menu-right a-shadow-sm border-0 a-user-setting-panel-one">
            <div class="dropdown-title a-pl-5">
              <div class="row justify-content-between no-gutters">
                <div class="col-9">
                  {{ appUser.name.toLocaleUpperCase() }}
                </div>
                <!-- <div class="col-2 text-right a-mr-10">
                  <span
                    class="z-one-icon appkiticon icon-person-fill pr-3"
                    routerLink="/profile"
                    title="Profile"
                  ></span>
                  <span
                    class="z-one-icon appkiticon icon-setting-outline"
                    routerLink="/setting"
                    title="Application Settings"
                  ></span>
                </div> -->
              </div>
            </div>
            <div class="dropdown-content a-pl-5">
              <div class="row a-pb-5">
                <div class="col-2">Email</div>
                <div class="col">{{ appUser.email }}</div>
              </div>
              <div class="row justify-content-end a-p-10" (click)="triggerLogout()">
                <div class="col-6 a-font-14 a-text-orange text-right a-cursor-pointer">
                  <span class="icon-login-outline"></span>
                  <span class="a-pl-5">Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!appUser.isAuthenticated && showLogin">
        <div class="a-logout">
          <button (click)="triggerLogin()" class="a-btn a-btn-primary a-btn-lg" [disabled]="disableLogin">
            Login
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>