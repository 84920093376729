import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector, private loggerService: LoggerService) {}

  // Global Error Handler
  handleError(error: Error) {
    this.loggerService.logException(error);
  }
}
