import { Injectable } from '@angular/core';

import { ObservableStore } from '@codewithdan/observable-store';
import { StoreActions } from '../store/store.actions';
import { StoreState } from '../store/storestate';

import { environment } from '../../../environments/environment';
import { AppUserModel } from '../../shared/models/appUser.model';
import { LookupCollectionModel } from '../../shared/models/lookupCollection.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends ObservableStore<StoreState> {
  private initialState: StoreState = {
    appUser: {} as AppUserModel,
    lookupCollection: {} as LookupCollectionModel
  };

  constructor() {
    super({
      trackStateHistory: environment.store.trackHistory,
      logStateChanges: environment.store.logChanges
    });

    this.initializeState();
  }

  isEmpty(obj: any) {
    return !obj || Object.keys(obj).length === 0;
  }

  initializeState() {
    this.setState(this.initialState, StoreActions.initializeState);
  }

  setUser(item: AppUserModel) {
    this.setState({ appUser: item }, StoreActions.addUser);
  }

  getUser(): AppUserModel {
    return this.getState().appUser;
  }
}
