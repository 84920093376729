<kendo-menu [items]="items">
  <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
    <span
      *ngIf="checkAuthorized(item)"
      class="{{ item.css }}"
      [kendoMenuItemLink]="index"
      routerLink="{{ item.url }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span (click)="stopRouterLink($event, item.url)">
        <span class="tl-menu-item">
          <span class="tl-menu-item-icon k-icon {{ item.icon }}"></span>
          <span class="tl-menu-item-text">{{ item.text }}</span>
        </span>
        <span *ngIf="item.items && item.items.length" [kendoMenuExpandArrow]="index"></span>
      </span>
    </span>
  </ng-template>
</kendo-menu>
