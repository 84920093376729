import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { from, throwError, Observable, Subject } from 'rxjs';

import { map, takeUntil, catchError } from 'rxjs/operators';

import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import { StoreService } from './store.service';
import { ApplicationInsightsService } from './application-insights.service';
import { LoggerService } from './logger.service';
import { HttpWrapperService } from './http-wrapper.service';

import { authConfig } from '../config/auth.config';
import { AppUserModel } from '../../shared/models/appUser.model';
import { ClaimsModel } from '../models/claims.model';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private oauthService: OAuthService,
    private storeService: StoreService,
    private http: HttpWrapperService,
    private appInsightsService: ApplicationInsightsService,
    private loggerService: LoggerService,
    private router: Router
  ) {
    this.configureEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private configureEvents() {
    this.oauthService.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        this.loggerService.logItem(event);
      }

      if (['token_received'].includes(event.type)) {
        this.oauthService.loadUserProfile().then(_ => this.loggerService.logMessage('Loading user profile'));
      }

      if (['session_terminated', 'session_error'].includes(event.type)) {
        this.signout('Auth service - session was terminated or had error, sign out!');
      }

      if (['logout'].includes(event.type)) {
        this.signout('Auth service - logging out!');
      }
    });

    window.addEventListener('storage', event => {
      if (event.key !== 'access_token' && event.key !== 'id_token' && event.key !== null) {
        return;
      }

      if (!this.oauthService.hasValidAccessToken() || !this.oauthService.hasValidIdToken()) {
        this.signout('Auth service - token has changed, sign out!');
      }
    });
  }

  configure() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  tryLogin(): Observable<AppUserModel> {
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      takeUntil(this.unsubscribe$),
      catchError(err => {
        this.storeService.initializeState();
        return throwError(err);
      }),
      map(isAuthenticated => {
        if (isAuthenticated) {
          const authenticatedUser = this.getUserFromToken();
          this.storeService.setUser(authenticatedUser);
          return authenticatedUser;
        }
        return {} as AppUserModel;
      })
    );
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  hasValidIdToken(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  login(additionalState?: string, params?: {}): void {
    this.oauthService.initCodeFlow(additionalState, params);
  }

  logout() {
    this.oauthService.logOut();
  }

  signout(message: string) {
    sessionStorage.removeItem('userrole');
    this.loggerService.logException(message);
    this.storeService.initializeState();
    this.appInsightsService.clearUserId();
    this.router.navigateByUrl(`/signout`).then(_ => this.loggerService.logMessage('Navigating to sign out!', null));
  }

  getUserFromToken(): AppUserModel {
    if (this.oauthService.hasValidIdToken()) {
      const identityClaims: ClaimsModel = this.oauthService.getIdentityClaims() as ClaimsModel;

      const appUser = {} as AppUserModel;
      appUser.uid = identityClaims.uid;
      appUser.email = identityClaims.cloudEmail;
      appUser.name = identityClaims.name;
      appUser.claims = identityClaims;
      appUser.tokenExpiry = new Date(this.oauthService.getIdTokenExpiration());
      appUser.initials = identityClaims.name
        .split(' ')
        .map(n => n[0])
        .join('');
      appUser.isAuthenticated = true;

      // Add appUser name for app insight logging
      this.appInsightsService.setUserId(appUser.name);

      this.loggerService.logMessage('Authenticated User');
      this.loggerService.logItem(appUser);

      return appUser;
    }

    return {} as AppUserModel;
  }

  authorize(authenticatedUser: AppUserModel): Observable<AppUserModel> {
    return this.http.get<AppUserModel>(environment.apiEndPoints.appuser + '/' + authenticatedUser.email).pipe(
      takeUntil(this.unsubscribe$),
      catchError(err => {
        this.storeService.initializeState();
        return throwError(err);
      }),
      map((appUser: AppUserModel) => {
        const authorizedUser = Object.assign(appUser, authenticatedUser) as AppUserModel;
        if (appUser.roleId > 0) {
          this.storeService.setUser(authorizedUser);

          this.loggerService.logMessage('Authorized User');
          this.loggerService.logItem(authorizedUser);

          sessionStorage.setItem('userrole', appUser.roleCode);
        } else {
          this.router
            .navigateByUrl(`/access-denied`)
            .then(_ => this.loggerService.logMessage('Failed to authorize user', null));
        }
        return authorizedUser;
      })
    );
  }

  getUserRole() {
    return sessionStorage.getItem('userrole');
  }
}
