import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pwc-app-blockuioverlay',
  templateUrl: './blockuioverlay.component.html',
  styleUrls: ['./blockuioverlay.component.scss']
})
export class BlockuioverlayComponent implements OnInit {
  @Input() message: string;
  constructor() {}

  ngOnInit() {}
}
